body {
   margin: 0;
   font-family: 'Raleway';
}

code {
   font-family: 'Raleway';
}

@page {
   size: auto;
}

@media print {
   html,
   body {
      zoom: 92%;
      height: initial;
      overflow: initial;
      -webkit-print-color-adjust: exact;
      background-color: white;
      margin: 0px;
   }

   .showContentInPrint {
      display: flex;
   }

   .removeContentInPrint {
      display: none;
   }
}
